<template>
    <div class="gradient-background">
        <div class="results-page">
            <el-progress v-if="loading" :percentage="progress"></el-progress>
            <div class="card-container" v-else>
                <div class="card" v-for="(row, index) in results" :key="index">
                    <div class="card-content">
                        <div class="card-details">
                            <h3 class="gradient-text">{{ row.vod_name }}</h3>
                            <el-tag class="gradient-tag">{{ row.type_name }} <span class="vod-time">| {{ row.vod_time }}</span> | {{ row.vod_remarks }}</el-tag>
                        </div>
                        <router-link :to="{ name: 'detail', params: { id: row.vod_id } }" class="icon-link">
                            <el-icon class="gradient-icon"><VideoPlay /></el-icon>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="floating-buttons">
            <button @click="goHome">返回首页</button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import {useRoute, useRouter} from 'vue-router'
import axios from 'axios'
import { ElIcon, ElProgress } from 'element-plus'
import { VideoPlay } from '@element-plus/icons-vue'

const route = useRoute()
const router = useRouter()
const results = ref([])
const loading = ref(false)
const progress = ref(0)

const goHome = () => {
    router.push('/')
}

const fetchResults = async () => {
    const { wd } = route.query
    loading.value = true
    progress.value = 20 // 初始进度

    try {
        const response = await axios.get(`/api/inc/api_mac10.php`, {
            params: {
                ac: 'list',
                wd: wd || '',
            }
        })

        progress.value = 60 // 中间进度

        if (response.data.code === 1) {
            results.value = response.data.list || []
        } else {
            console.error('获取数据时出错：', response.data.msg)
        }

        progress.value = 100 // 完成进度
    } catch (error) {
        console.error('网络错误：', error)
    } finally {
        setTimeout(() => {
            loading.value = false
            progress.value = 0 // 重置进度
        }, 500) // 添加一个轻微的延迟以实现更平滑的过渡
    }
}

// 监听路由变化
watch(route, fetchResults)

// 初始加载
onMounted(fetchResults)
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.floating-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.floating-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.floating-buttons button:hover {
    background-color: #0056b3;
}
.gradient-background {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #ffccff, #ccffff, #cc99ff);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.results-page {
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
    max-width: 900px;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    position: relative; /* 为了让进度条能覆盖整个容器 */
}

.card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.card {
    background: rgba(0, 188, 212, 0);
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.card-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden; /* 防止文字溢出 */
    max-width: 70%; /* 限制最大宽度 */
}

.gradient-text {
    margin: 0;
    font-size: 18px;
    background: linear-gradient(135deg, #000000, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gradient-tag {
    font-size: 14px;
    background: linear-gradient(135deg, #030303, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* 限制最大宽度 */
}

.icon-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px; /* 增加图标的大小 */
    color: #00d463;
    text-decoration: none;
    min-width: 40px; /* 确保按钮宽度 */
    min-height: 40px; /* 确保按钮高度 */
}

.icon-link:hover {
    color: #9e0000;
}

/* 移动端样式 */
@media (max-width: 768px) {
    .gradient-background {
        align-items: flex-start;
    }

    .results-page {
        padding: 10px;
        border-radius: 0;
        box-shadow: none;
        min-height: 100vh;
    }

    .card {
        flex-direction: row; /* 保持图标在右侧 */
        justify-content: space-between; /* 保持左右对齐 */
        align-items: center;
    }

    .card-content {
        flex-direction: row; /* 保持图标在右侧 */
        justify-content: space-between; /* 保持左右对齐 */
        align-items: center;
        width: 100%;
    }

    .card-details {
        max-width: 60%; /* 移动端限制最大宽度 */
    }

    .vod-time {
        display: none; /* 在移动端隐藏更新时间 */
    }
}
</style>
